import React from "react";
import tw from "tailwind-styled-components";

function NotFound() {
  return (
    <Root>
      <div>
        <h1 className="pt-24 text-white255 font-bold text-xl text-center">
          Welcome To The K Club{" "}
        </h1>
      </div>
      <div className="pt-24 px-2">
        <h1 className="animate-pulse text-primary font-bold text-3xl text-center scale-110">
          Your Page Not Found{" "}
        </h1>
      </div>
    </Root>
  );
}

const Root = tw.div`
w-full 
h-full
flex 
flex-col 
m-auto 
items-center 

`;

export default NotFound;
