import _ from "lodash";
import * as types from "./types";

const initialState = {
  bookingData: [],
  cancelBooking: [],
  addToCartData: [],
  listingBookingData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SELECTED_PLAYER: {
      if (action?.payload?.localSave) {
        return {
          ...state,
          addToCartData: action?.payload?.localdata,
        };
      } else {
        if (action?.payload?.save) {
          if (action?.payload?.is_Update) {
            const updatedArray = state.addToCartData.map((obj) =>
              obj?.teeTime?.teeTimeData?.id ===
                action?.payload?.props?.teeTimeData?.id &&
              obj?.teeTime?.teeTimeData?.course ===
                action?.payload?.props?.teeTimeData?.course &&
              obj?.teeTime?.teeTimeData?.time ===
                action?.payload?.props?.teeTimeData?.time
                ? { ...obj, price: action?.payload?.price }
                : obj
            );
            localStorage.setItem("LocalCartData", JSON.stringify(updatedArray));
            return {
              ...state,
              addToCartData: updatedArray,
            };
          } else {
            if (
              state.addToCartData.find(
                (e) =>
                  e?.teeTime?.teeTimeData?.id ===
                    action?.payload?.props?.teeTimeData?.id &&
                  e?.teeTime?.teeTimeData?.course ===
                    action?.payload?.props?.teeTimeData?.course &&
                  e?.teeTime?.teeTimeData?.time ===
                    action?.payload?.props?.teeTimeData?.time
              )
            ) {
              const arr = _.filter(state.addToCartData, function (e) {
                return (
                  e?.teeTime?.teeTimeData?.id !==
                    action?.payload?.props?.teeTimeData?.id ||
                  e?.teeTime?.teeTimeData?.course !==
                    action?.payload?.props?.teeTimeData?.course ||
                  e?.teeTime?.teeTimeData?.time !==
                    action?.payload?.props?.teeTimeData?.time
                );
              });
              localStorage.setItem("LocalCartData", JSON.stringify(arr));
              localStorage.setItem(
                "CartTimer",
                arr.length ? new Date().getTime() : ""
              );
              return {
                ...state,
                addToCartData: arr,
              };
            } else {
              localStorage.setItem(
                "LocalCartData",
                JSON.stringify([
                  ...state.addToCartData,
                  {
                    teeTime: action?.payload?.props,
                    price: action?.payload?.price,
                  },
                ])
              );
              localStorage.setItem("CartTimer", new Date().getTime());
              return {
                ...state,
                addToCartData: [
                  ...state.addToCartData,
                  {
                    teeTime: action?.payload?.props,
                    price: action?.payload?.price,
                  },
                ],
              };
            }
          }
        } else {
          localStorage.setItem("LocalCartData", JSON.stringify([]));
          localStorage.setItem("CartTimer", "");
          return {
            ...state,
            addToCartData: [],
          };
        }
      }
    }
    case types.GET_BOOKING_DATA: {
      return {
        ...state,
        bookingData: action?.payload,
      };
    }
    case types.CANCEL_BOOKING: {
      return {
        ...state,
        cancelBooking: action?.payload,
      };
    }
    case types.GET_LISTING_BOOKING_DATA: {
      return {
        ...state,
        listingBookingData: action?.payload?.data?.data,
      };
    }
    default:
      return state;
  }
};

export default reducer;
