import React, { useReducer } from "react";
import { useDispatch } from "react-redux";
import tw from "tailwind-styled-components";
import Dialog from "@mui/material/Dialog";
import { Zoom } from "@mui/material";
import {
  PrimaryText,
  CustomButton,
  CustomInput,
  TostMessage,
} from "@components";
import { IsValidatePassword } from "@utils";
import { userRegister, getUser } from "@store";
import "../../containerStyle.css";

function RegisterModal(props) {
  const dispatch = useDispatch();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstNameError: false,
      lastNameError: false,
      userNameError: false,
      emailError: false,
      passwordError: false,
      confirmPasswordError: false,
      validatePasswordError: false,
      shortPasswordError: false,
      btnLoading: false,
      alertMessage: false,
      alertMessageTxt: null,
    }
  );

  const apiData = {
    firstName: state.firstName,
    lastName: state.lastName,
    identifier: state.email,
    username: state.userName,
    email: state.email,
    password: state.password,
  };

  const register = async () => {
    if (
      state.firstName &&
      state.lastName &&
      state.userName &&
      state.email &&
      state.password
    ) {
      if (state.password.length < 8) {
        setState({ shortPasswordError: true });
      } else {
        setState({ shortPasswordError: false });
      }
      if (IsValidatePassword(state.password)) {
        if (state.password !== state.confirmPassword) {
          setState({ confirmPasswordError: true });
        } else {
          setState({ btnLoading: true });
          await dispatch(userRegister(apiData)).then((res) => {
            if (res?.payload?.data && res?.payload?.status === 200) {
              dispatch(getUser()).then(() => {
                props.openSuccessModal();
                setState({
                  email: "",
                  lastName: "",
                  userName: "",
                  password: "",
                  firstName: "",
                  confirmPassword: "",
                  emailError: false,
                  btnLoading: false,
                  lastNameError: false,
                  userNameError: false,
                  passwordError: false,
                  firstNameError: false,
                  confirmPasswordError: false,
                  validatePasswordError: false,
                });
              });
            } else {
              props.openSuccessModal();
              setState({
                btnLoading: false,
              });
            }
          });
        }
      } else {
        setState({
          validatePasswordError: true,
          alertMessage: true,
          alertMessageTxt:
            "Password must contain one uppercase letter, one number, and one special character !",
        });
      }
    } else {
      setState({
        firstNameError: !state.firstName,
        lastNameError: !state.lastName,
        userNameError: !state.userName,
        emailError: !state.email,
        passwordError: !state.password,
        confirmPasswordError: false,
        validatePasswordError: false,
      });
    }
  };

  const login = () => {
    setState({
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstNameError: false,
      lastNameError: false,
      userNameError: false,
      emailError: false,
      passwordError: false,
      confirmPasswordError: false,
      validatePasswordError: false,
      shortPasswordError: false,
    });
    props.closeRegisterModal();
  };

  const closeModal = () => {
    props.closeModal();
    setState({
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstNameError: false,
      lastNameError: false,
      userNameError: false,
      emailError: false,
      passwordError: false,
      confirmPasswordError: false,
      validatePasswordError: false,
      shortPasswordError: false,
    });
  };

  const Title = (props) => {
    return (
      <div className="flex items-center justify-center w-full h-auto mb-4">
        <PrimaryText className="text-2xl text-textColor2 font-bold font-[Cambay] text-center">
          {props.title}
        </PrimaryText>
      </div>
    );
  };

  return (
    <>
      <Dialog
        open={props.openModal}
        onClose={() => closeModal()}
        TransitionComponent={Zoom}
        sx={{
          height: "90%",
          marginTop: "3%",
        }}
      >
        <Content>
          <Title title={"REGISTER"} />
          <Line />
          <Container>
            {CustomInput({
              title: "First Name",
              value: `${state.firstName}`,
              onChange: (event) => {
                const regex = /^[a-zA-Z\b]+$/;
                if (
                  event.target.value === "" ||
                  regex.test(event.target.value)
                ) {
                  setState({
                    firstName: event.target.value,
                    firstNameError: false,
                  });
                }
              },
              maxLength: 30,
              error: state.firstNameError,
              errorMessage: "Please Enter First Name",
              icon: true,
            })}
            {CustomInput({
              title: "Last Name",
              value: `${state.lastName}`,
              onChange: (event) => {
                const regex = /^[a-zA-Z\b]+$/;
                if (
                  event.target.value === "" ||
                  regex.test(event.target.value)
                ) {
                  setState({
                    lastName: event.target.value,
                    lastNameError: false,
                  });
                }
              },
              maxLength: 30,
              error: state.lastNameError,
              errorMessage: "Please Enter Last Name",
              icon: true,
            })}
            {CustomInput({
              title: "Username",
              value: `${state.userName}`,
              onChange: (event) => {
                setState({
                  userName: event.target.value,
                  userNameError: false,
                });
              },
              maxLength: 30,
              error: state.userNameError,
              errorMessage: "Please Enter Username",
              icon: true,
            })}
            {CustomInput({
              title: "Email",
              value: `${state.email}`,
              onChange: (event) => {
                setState({ email: event.target.value, emailError: false });
              },
              maxLength: 40,
              error: state.emailError,
              errorMessage: "Please Enter Email",
              icon: true,
            })}
            {CustomInput({
              title: "Password",
              value: `${state.password}`,
              onChange: (event) => {
                setState({
                  password: event.target.value,
                  passwordError: false,
                  validatePasswordError: false,
                  shortPasswordError: false,
                });
              },
              maxLength: 30,
              error:
                state.passwordError ||
                state.shortPasswordError ||
                state.validatePasswordError,
              errorMessage: state.shortPasswordError
                ? "The value. is too short (min:{8})."
                : state.validatePasswordError
                ? "Password must contain one uppercase letter, one number, and one special character"
                : "Please Enter Password",
              secureText: true,
              icon: true,
            })}
            {CustomInput({
              title: "Confirm Password",
              value: `${state.confirmPassword}`,
              onChange: (event) => {
                setState({
                  confirmPassword: event.target.value,
                  confirmPasswordError: false,
                  validatePasswordError: false,
                });
              },
              maxLength: 30,
              error: state.confirmPasswordError,
              errorMessage: "Please Enter Confirm Password",
              passwordError: state.confirmPasswordError,
              secureText: true,
              icon: true,
            })}

            <div className="mt-6 mb">
              <CustomButton
                userLoading={state.btnLoading}
                onClick={register}
                value={"REGISTER"}
                color="primary"
              />
            </div>
            <Btn_View>
              <PrimaryText className="text-textColor2 text-sm leading-normal font-[Cambay]">
                Already Register?
              </PrimaryText>
              <button className={login_btn} onClick={login}>
                Login
              </button>
            </Btn_View>
          </Container>
        </Content>
      </Dialog>
      {state.alertMessageTxt && (
        <TostMessage
          open={state.alertMessage}
          onClose={() => {
            setState({
              alertMessage: false,
              alertMessageTxt: null,
            });
          }}
          title={state.alertMessageTxt}
          type={"info"}
        />
      )}
    </>
  );
}

const Content = tw.div`
w-auto
h-auto 
sm:py-4
sm:px-6
sm2:p-[12px]
p-auto
md:overflow-x-hidden
sm:overflow-x-hidden
overflow-x-auto
overflow-y-auto
custom-scroll
`;
const Container = tw.div`
md:w-[480px]
sm:w-full
w-full
h-auto 
flex 
flex-col
`;
const Line = tw.div`
w-full
h-[1.1px] 
bg-[#C5C5C5] 
my-2
`;
const Btn_View = tw.div`
w-full 
h-auto 
flex flex-row 
items-center justify-center
`;
const login_btn = `
w-auto 
h-auto 
p-2 
text-sm 
leading-normal 
font-[Cambay] 
hover:underline underline-offset-4 
text-[#7D8AF3]
`;

export default RegisterModal;
